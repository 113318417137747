



























































import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import CompanyService from "@/services/CompanyService";
import SystemSettingService from "@/services/SystemSettingService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import AddressUtil from "@/utilities/AddressUtil";

const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");

@Component({
    components: { AlertDialogView },
    data: () => ({
        name: "",
        facebookUrl: "",
        registrationName: "",
        registrationNo: "",
        fullAddress: "",
        contactNo: "",
        email: ""
    }),
    computed: {
        screenSmall: function() {
            return this.$vuetify.breakpoint.smAndDown;
        },
    }
})
export default class ContactUsView extends Vue {
    private alertDialog = new AlertDialogProperties();
    private companyService = new CompanyService();
    private systemSettingService = new SystemSettingService();

    public async created() {
        await this.loadCompany();
        await this.loadFacebookUrl();
    }

    public async loadCompany() {
        try {
            const r = await this.companyService.summary({ loadAddress: true });
            this.$data.name = r.data.name;
            this.$data.registrationName = r.data.registrationName;
            this.$data.registrationNo = r.data.registrationNo;
            
            const address = r.data.address;
            if (address) {
                this.$data.fullAddress = AddressUtil.build(address);
                this.$data.contactNo = address.contactNo;
                this.$data.email = address.email;
            }
        } catch (e) {
            if (await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public async loadFacebookUrl() {
        try {
            const r = await this.systemSettingService.facebookUrl();
            this.$data.facebookUrl = r.data;
        } catch (e) {
            if (await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public alertDialogClicked() {
        this.alertDialog.visible = false;
    }
}
